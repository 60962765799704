<script setup lang="ts">
import { useOnboardingStore } from '@/stores/onboarding';
import type { IOnboardingStep } from '@/types/IAssetType';
import { ref, watch } from 'vue';


const onboardingStore = useOnboardingStore()

const myStep = ref(onboardingStore.steps.find(x => x.tag === 'SelectAssetTypes') as IOnboardingStep)

watch(
    () => [onboardingStore.assetTypes],
    () => {
        myStep.value.canGoNext = onboardingStore.assetTypes.some(x => x.isSelected === true);
        onboardingStore.assetTypes.forEach((asset) => {

            const steps = onboardingStore.steps.filter(x => x.assetType !== undefined && x.assetType === asset.title);
            if (steps && steps.length > 0) {
                steps.forEach(st => st.show = asset.isSelected)
            }

        })

    },
    { deep: true }
);

</script>

<template>

    <!-- <div class=" bg-slate-300 -mx-4 -mt-5 sm:-m-6  p-4 sm:px-6 sm:py-4 ">
        <h3 class="text-xl font-semibold leading-6 text-gray-900">Welcome</h3>
        <p class="mt-0 max-w-4xl text-sm text-gray-500">Please select asset types which you want to track.</p>
    </div> -->


    <div class="mt-6 mb-6">
        <h3 class="text-xl font-semibold leading-6 text-gray-900">Welcome</h3>
        <p class="max-w-4xl text-base text-gray-500 mt-6">We will guide you in starting to use Winner.</p>
    </div>


</template>