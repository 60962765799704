<script setup lang="ts">

import { computed, ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
  PresentationChartLineIcon,
  XMarkIcon,
  ChartBarSquareIcon,
  BanknotesIcon,
  CurrencyDollarIcon,
  BuildingLibraryIcon, RectangleStackIcon, CameraIcon, ShoppingCartIcon,
  Cog6ToothIcon,
  XCircleIcon,
  RocketLaunchIcon
} from '@heroicons/vue/24/outline'
import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid'

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

import { Float } from "@headlessui-float/vue";
import router from '@/router'

import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'




const sidebarOpen = ref(false)



const store = useCounterStore()
const authStore = useAuthStore()
const onboardingStore = useOnboardingStore()



const userNavigation = [
  // { name: 'Your Profile', href: '#', icon: UserCircleIcon, description: "See your profile" },
  { name: 'Settings', href: '#', icon: Cog6ToothIcon, description: "See your profile", click: dummy },
  { name: 'Sign out', href: '#', icon: XCircleIcon, description: "Securely close your session", click: logOff },
]

function logOff() {
  store.logoff();
  authStore.logoff();
  onboardingStore.logoff();

  router.push("/");
}

function dummy() {
  onboardingStore.show = true;
}

const navigation = ref([
  { name: 'Dashboard', href: '/home/dashboard', icon: PresentationChartLineIcon, current: false },
  { name: 'Accounts', href: '/home/accounts', icon: BanknotesIcon, current: false },
  { name: 'Stocks', href: '/home/stocks', icon: ChartBarSquareIcon, current: false },
  { name: 'Coins', href: '/home/coins', icon: CurrencyDollarIcon, current: false },
  { name: 'Funds', href: '/home/funds', icon: BuildingLibraryIcon, current: false },
  { name: 'Commodities', href: '/home/commodities', icon: ShoppingCartIcon, current: false },
  { name: 'Snapshots', href: '/home/snapshots', icon: CameraIcon, current: false },
  { name: 'Logs', href: '/home/logs', icon: RectangleStackIcon, current: false },
  // { name: 'Setup My Account', href: '/home/logs', icon: RocketLaunchIcon, current: false },
])

const teams = [
  { id: 1, name: 'Planetaria', href: '#', initial: 'P', current: false },
  { id: 2, name: 'Protocol', href: '#', initial: 'P', current: false },
  { id: 3, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
]



import { useCounterStore } from '@/stores/counter'
import { useAuthStore } from '@/stores/auth'
import { onMounted } from "vue";



import { AccountTypeEnum, CreateNewAccountSuggestionTypeEnum, type ApiResult, type CreateNewAccountRequest, type CreateNewAccountResult } from "@/types/SharedTypes";
import ApiService from "@/types/ApiService";
import { NotificationItemType } from "@/types/NotificationItem";
import { UserIcon } from '@heroicons/vue/24/solid';
import { HubConnectionState } from '@microsoft/signalr'
import SelectUsageTypesComponent from '@/components/onboarding/SelectAssetTypesComponent.vue'
import OnboardingComponent from '@/components/onboarding/OnboardingComponent.vue'
import { useOnboardingStore } from '@/stores/onboarding'



const hubStateColor = computed(() => {
  if (store.hubState === undefined) {
    return "bg-gray-400"
  }
  else if (store.hubState === HubConnectionState.Connected) {
    return "bg-green-400"
  }
  else if (store.hubState === HubConnectionState.Connecting) {
    return "bg-green-400/50"
  }
  else if (store.hubState === HubConnectionState.Disconnected) {
    return "bg-red-400"
  }
  else if (store.hubState === HubConnectionState.Disconnecting) {
    return "bg-red-400/50"
  }
  else if (store.hubState === HubConnectionState.Reconnecting) {
    return "bg-green-400/50"
  }
})


onMounted(async () => {

  await store.start();
})


</script>

<template>
  <OnboardingComponent></OnboardingComponent>


  <div id="homeView" class="w-full max-h-full h-full overflow-hidden flex flex-row ">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog class="relative z-50 xl:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100"
                leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-gray-700" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 ring-1 ring-gray-300">
                <div class="flex h-16 shrink-0 items-center">
                  <!-- <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Your Company" /> -->
                  <img class="h-5 w-auto" src="/src/assets/winner.png" alt="Your Company" />
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <button v-if="item.href" @click="sidebarOpen = false" class="w-full">
                            <RouterLink :to="item.href" activeClass="bg-gray-200 text-gray-900"
                              class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-200 hover:text-gray-900">
                              <component :is="item.icon" class="h-6 w-6 shrink-0 text-gray-500" aria-hidden="true" />
                              {{ item.name }}
                            </RouterLink>
                          </button>
                          <div v-else :to="item.href" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-200 hover:text-gray-900 opacity-40">
                            <component :is="item.icon" class="h-6 w-6 shrink-0 text-gray-500" aria-hidden="true" />
                            {{ item.name }}
                          </div>
                        </li>
                      </ul>
                    </li>
                    <!-- <li>
                      <div class="text-xs font-semibold leading-6 text-gray-500">Your teams</div>
                      <ul role="list" class="-mx-2 mt-2 space-y-1">
                        <li v-for="team in teams" :key="team.name">
                          <a :href="team.href"
                            :class="[team.current ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:bg-gray-200 hover:text-gray-900', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']">
                            <span
                              class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-400 bg-gray-200 text-[0.625rem] font-medium text-gray-500 group-hover:text-gray-900">{{
                                team.initial }}</span>
                            <span class="truncate">{{ team.name }}</span>
                          </a>
                        </li>
                      </ul>
                    </li> -->
                    <!-- <li class="-mx-6 mt-auto">
                      <a href="#" class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-200">
                        <img class="h-8 w-8 rounded-full bg-gray-200"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                        <UserIcon class="h-8 w-8 rounded-full text-gray-400 bg-gray-300 p-1"></UserIcon>
                        <span class="sr-only">Your profile</span>
                        <span aria-hidden="true">{{ authStore.signUpResult?.displayName }}</span>
                      </a>
                    </li> -->

                    <Menu class="">

                      <Float placement="top-start" :shift="60" :offset="4" flip enter="transition duration-200 ease-out" enter-from="scale-95 opacity-</Float>0" enter-to="scale-100 opacity-100"
                        leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
                        <MenuButton as="li" class="-mx-6 mt-auto bg-gray-300">
                          <div class="flex w-full min-w-full items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-200 cursor-pointer">

                            <span class="relative inline-block">
                              <UserIcon class="h-8 w-8 rounded-full text-gray-400 bg-gray-200 p-1"></UserIcon>
                              <span class="absolute right-0 top-0 block h-2 w-2 rounded-full ring-2 ring-white" :class="hubStateColor"></span>
                            </span>
                            <span class="sr-only">Your profile</span>
                            <span aria-hidden="true">{{ authStore.signUpResult?.displayName }}</span>
                          </div>
                        </MenuButton>


                        <MenuItems
                          class="ring-black ring-opacity-5 focus:outline-none w-screen max-w-xs flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                          <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">

                          <div class="group relative flex gap-x-6 p-4 hover:bg-gray-200 cursor-pointer" @click="item.click">

                            <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-200 group-hover:bg-gray-100">
                              <component :is="item.icon" class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                            </div>
                            <div>
                              <div class="font-semibold text-gray-900 group-hover:text-indigo-600">
                                {{ item.name }}
                                <span class="absolute inset-0" />
                              </div>
                              <p class="mt-1 text-xs text-gray-600"> {{ item.description }}</p>
                            </div>

                            <!-- efe
      <a :href="item.href" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">{{ item.name }}</a> -->
                          </div>
                          </MenuItem>
                        </MenuItems>


                      </Float>
                    </Menu>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- <div class="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col"> -->
    <div class="hidden xl:block xl:min-w-72  xl:w-72 xl:flex-col bg-red-50 ">
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-50 px-6 ring-1 ring-gray-200 h-full">
        <div class="flex h-16 shrink-0 items-center">
          <!-- <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Your Company" /> -->
          <img class="h-5 w-auto" src="/src/assets/winner.png" alt="Your Company" />
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">

                  <RouterLink v-if="item.href" :to="item.href" activeClass="bg-gray-200 text-gray-900"
                    class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-200 hover:text-gray-900">
                    <component :is="item.icon" class="h-6 w-6 shrink-0 text-gray-500" aria-hidden="true" />
                    {{ item.name }}
                  </RouterLink>
                  <div v-else :to="item.href" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-200 hover:text-gray-900 opacity-40">
                    <component :is="item.icon" class="h-6 w-6 shrink-0 text-gray-500" aria-hidden="true" />
                    {{ item.name }}
                  </div>
                </li>
              </ul>
            </li>




            <Menu class="">

              <Float placement="top-start" :shift="60" :offset="4" flip enter="transition duration-200 ease-out" enter-from="scale-95 opacity-</Float>0" enter-to="scale-100 opacity-100"
                leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class>
                <MenuButton as="li" class="-mx-6 mt-auto bg-gray-300">
                  <div class="flex w-full min-w-full items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-300 cursor-pointer">

                    <span class="relative inline-block">
                      <UserIcon class="h-8 w-8 rounded-full text-gray-400 bg-gray-200 p-1"></UserIcon>
                      <span class="absolute right-0 top-0 block h-2 w-2 rounded-full ring-2 ring-white" :class="hubStateColor"></span>
                    </span>
                    <span class="sr-only">Your profile</span>
                    <span aria-hidden="true">{{ authStore.signUpResult?.displayName }}</span>
                  </div>


                </MenuButton>


                <MenuItems class="ring-black ring-opacity-5 focus:outline-none w-screen max-w-xs flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">

                  <div class="group relative flex gap-x-6 p-4 hover:bg-gray-200 cursor-pointer" @click="item.click">

                    <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-200 group-hover:bg-gray-100">
                      <component :is="item.icon" class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                    </div>
                    <div>
                      <div class="font-semibold text-gray-900 group-hover:text-indigo-600">
                        {{ item.name }}
                        <span class="absolute inset-0" />
                      </div>
                      <p class="mt-1 text-xs text-gray-600"> {{ item.description }}</p>
                    </div>


                  </div>
                  </MenuItem>
                </MenuItems>


              </Float>
            </Menu>

          </ul>
        </nav>
      </div>
    </div>

    <div class="h-full max-h-full w-full flex flex-col overflow-x-auto ">
      <div class="flex h-16 flex-shrink-0  items-center gap-x-6 border-b  xl:border-l border-gray-200 bg-white px-4 shadow-sm sm:px-6 lg:px-8 xl:hidden">
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 xl:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-5 w-5" aria-hidden="true" />
        </button>

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <!-- <form class="flex flex-1" action="#" method="GET">
            <label for="search-field" class="sr-only">Search </label>
            <div class="relative w-full">
              <MagnifyingGlassIcon class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500" aria-hidden="true" />
              <input id="search-field" class="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 text-gray-900 focus:ring-0 sm:text-sm" :placeholder="'Search...'" type="search"
                name="search" />
            </div>
          </form> -->
          <!-- osman -->
          <span class="flex-grow"></span>
          <ul role="list" class="-mx-2 space-x-2 xl:hidden flex items-center justify-end ">

            <li v-for="item in navigation" :key="item.name">
              <button v-if="item.href" @click="sidebarOpen = false">
                <RouterLink :to="item.href" activeClass="bg-gray-200 text-gray-900"
                  class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-200 hover:text-gray-900">
                  <component :is="item.icon" class="h-6 w-6 shrink-0 text-gray-500" aria-hidden="true" />
                </RouterLink>
              </button>

            </li>
          </ul>


        </div>
      </div>

      <div class="h-full max-h-full overflow-hidden">
        <RouterView></RouterView>
      </div>
    </div>

  </div>
</template>
