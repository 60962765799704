<script setup lang="ts">
import { type ApiResult, type SearchStockTickerRequest, type SearchStockTickerResult, type SearchStockTickerResultItem } from '@/types/SharedTypes'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { computed, ref, watch, type PropType } from 'vue'
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions
} from '@headlessui/vue'
import ApiService from '@/types/ApiService';
import { useCounterStore } from '@/stores/counter'
const model = defineModel({
    type: Object as PropType<SearchStockTickerResultItem | undefined>,

});

const props = defineProps({
    label: {
        type: String,
        required: false,
        default: "Select stock"
    },


})


const serverStocks = ref([] as Array<SearchStockTickerResultItem>)

const formSymbol = ref('')

const apiService = new ApiService<ApiResult<SearchStockTickerResult>>();

const store = useCounterStore()

// const props = defineProps({
//     selectedTickerId: {
//         type: Number,
//         required: false
//     }
// })



watch(formSymbol, async (newQuestion, oldQuestion) => {
    if (newQuestion && newQuestion.length > 0) {

        const searchStockTickerRequest = { searchString: formSymbol.value } as SearchStockTickerRequest;
        try {
            const response = await apiService.post(store.apiUrl + "StockTicker/SearchStockTicker", searchStockTickerRequest, null);
            if (response) {
                if (response.data && response.data.error) {
                } else {

                    serverStocks.value = response.data.result.items;
                }
            }
        } catch (error) { }
    }
    return []
})

const filteredPeople = computed(() => {
    if (
        serverStocks.value !== undefined &&
        serverStocks.value !== null &&
        serverStocks.value.length > 0
    ) {
        return serverStocks.value
    }
    return []
})

// watch(
//     () => props.selectedTickerId,
//     async (first, second) => {
//         if (first) {
//             const searchStockTickerRequest = { searchId: props.selectedTickerId } as SearchStockTickerRequest;
//             try {
//                 const response = await apiService.post("https://localhost:7225/StockTicker/SearchStockTicker", searchStockTickerRequest, null);
//                 if (response) {
//                     if (response.data && response.data.error) {
//                     } else {
//                         serverStocks.value = response.data.result.items;
//                         if (serverStocks.value && serverStocks.value.length > 0) {
//                             model.value = serverStocks.value[0]
//                         }
//                     }
//                 }
//             } catch (error) { }
//         }
//     }
// )

function comboDisplayValue(selected: unknown): string {
    if (selected === undefined) {

        return ''
    }
    if (implementsMyInterface(selected)) {

        return selected.ticker + ' > ' + selected.name
    }

    return ''
}

function implementsMyInterface(obj: unknown): obj is SearchStockTickerResultItem {
    if (typeof obj === 'object' && obj !== null) {
        return 'ticker' in obj && 'name' in obj
    }
    return false
}


</script>

<template>
    <Combobox as="div" v-model="model" class="sm:col-span-2">
        <ComboboxLabel v-if="label && label.length > 0" class="block text-sm font-medium leading-6 text-gray-900">{{ label }}
        </ComboboxLabel>
        <div class="relative" :class="[label ? 'mt-2' : '']">
            <ComboboxInput @change="formSymbol = $event.target.value" autocomplete="off" placeholder="Write company name or ticker..."
                class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                :display-value="comboDisplayValue" :class="[model ? 'font-semibold' : '']">
            </ComboboxInput>
            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>

            <ComboboxOptions class="absolute mt-1 z-10  max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">

                <ComboboxOption v-for="serverStock in serverStocks" :key="serverStock.id" :value="serverStock" as="template" v-slot="{ active, selected }">
                    <li :class="[
                        'relative cursor-default select-none py-2 pl-8 pr-4',
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    ]">
                        <div class="flex">
                            <span class="block truncate font-semibold flex-1">
                                {{ serverStock.ticker }}
                            </span>
                            <span class="opacity-70 font-regular">{{
                                serverStock.stockMarketShortName
                                }}</span>
                        </div>

                        <span :class="['block truncate text-gray-700', active ? 'text-white' : '']">
                            {{ serverStock.name }}
                        </span>

                        <span v-if="selected" :class="[
                            'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            active ? 'text-white' : 'text-indigo-600'
                        ]">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                </ComboboxOption>
            </ComboboxOptions>
        </div>
    </Combobox>
</template>
